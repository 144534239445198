import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { Tooltip } from './tooltip'
/** @jsxImportSource @emotion/react */

interface FieldTooltipErrorProps {
  error?: FieldError
  className?: string
  isAbsolute?: boolean
  minLength?: number
  maxLength?: number
  disableWrongDecsription?: boolean
}

const FieldTooltipError = ({
  error,
  className,
  maxLength,
  minLength,
  isAbsolute,
  disableWrongDecsription
}: FieldTooltipErrorProps) => {
  const { t } = useTranslation('common')
  return error ? (
    <Tooltip
      placement="top"
      colorVariant="error"
      isRelative
      visibleMobile
      trigger={
        <div
          css={[
            tw`text-error w-4 text-lg flex justify-center items-start`,
            isAbsolute && tw`absolute top-4 right-4`
          ]}
          className={className}
        >
          <FontAwesomeIcon tw="text-error" icon={faCircleExclamation} />
        </div>
      }
    >
      {/* {t(
        `form.validation.${
          error?.message ? error?.message : error?.type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }` as any,
        {
          maxLength,
          minLength
        }
      )} */}
      {!disableWrongDecsription ? (
        <div>
          {t(`form.validation.${error?.message ? error?.message : error?.type}` as any, {
            maxLength,
            minLength
          })}
        </div>
      ) : (
        <div>
          {`${error?.message ? error?.message : error?.type}${error.type === 'maxLength' ? maxLength : minLength}`}
        </div>
      )}
    </Tooltip>
  ) : null
}

export { FieldTooltipError }
